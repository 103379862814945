.articles-list {
  margin-right: 273px;
  margin-left: 108px;
  width: calc(100% - 381px);

  .grid-articles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .grid-news {
      width: calc((100% - 148px) / 3);

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      .intro, .image {
        background: var(--unnamed-color-efefef);
      }
    }
  }

}