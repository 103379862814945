section.flats-with-us {
  .container {
    .all-cnt {
      display: flex;
      flex-direction: column;
      width: 100%;

      .top-title {
        display: flex;
        margin-top: 233px;
        margin-bottom: 65px;

        &:before {
          content: "";
          height: auto;
          display: block;
          width: calc(10% + 165px);
        }

        .title-wrapper {
          width: calc(90% + 165px);

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            font: normal normal bold 100px/100px Poppins;
            letter-spacing: 0px;
            color: #00B7FF;
          }

          h3.title-2 {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            text-align: left;
            font: italic normal bold 100px/100px Playfair Display;
            letter-spacing: 0px;
            color: #026CF5;
          }
        }
      }

      .images-info {
        display: flex;
        width: calc(90% + 165px);

        &:before {
          content: "";
          height: auto;
          display: block;
          width: calc(10% + 165px);
        }

        .image-info-rows {
          padding-right: 265px;


          .image-info-row {
            display: flex;
            flex-direction: row;
            align-items: center;

            &.reverse {
              flex-direction: row-reverse;
            }

            .col-image {
              width: 40%;
            }

            .col-text {
              .ul-list {
                padding-right: 200px;

                .list-element {
                  display: flex;
                  font-weight: var(--unnamed-font-weight-medium);
                  letter-spacing: var(--unnamed-character-spacing-0);
                  text-align: left;
                  font-family: Poppins;
                  font-size: 25px;
                  line-height: 28px;
                  letter-spacing: 0px;
                  color: #484848;
                  opacity: 1;

                  .text {
                    width: calc(100% - 92px);
                  }

                  &:before {
                    content: "";
                    display: block;
                    width: 72px;
                    margin-right: 20px;
                    height: 3px;
                    background-color: var(--unnamed-color-00b7ff);
                    margin-top: 14px;
                  }
                }
              }
            }
          }
        }


      }

      .see-more-btn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-right: 265px;
        margin-top: 176px;
        margin-bottom: 65px;

        a.link-blue-regular {
          width: auto;
          text-wrap: nowrap;
          padding-right: 45px;
          padding-left: 45px;
        }
      }
    }
  }
}