section.bussines-intro-second {
  background: #026CF5;

  .container {
    .row-1 {
      display: flex;
      justify-content: space-between;
      margin-left: 279px;

      .col-text {
        width: 50%;

        .title {
          padding-top: 52px;
          margin-bottom: 50px;

          h2.title-1 {
            font: normal normal bold 100px/100px Poppins;
            letter-spacing: 0px;
            color: #00B7FF;
          }
          h3.title-2  {
            font: italic normal bold 100px/100px Playfair Display;
            letter-spacing: 0px;
            color: #FFFFFF;
          }
        }

        .desc {
          font: normal normal normal 28px/42px Poppins;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }

      .col-image {
        margin-left: 65px;
        padding-top: 530px;
        width: 48%;

        .img-cnt {
          background: #00B7FF;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 125px;

          img {
            margin-top: -265px;
            max-width: calc(100% - 125px);
            min-width: calc(100% - 125px);
          }
        }

        .logo-under {
          margin-bottom: -400px;
          margin-right: 10%;
          padding-top: 80px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .row-2 {
      background: #0049A7;
      margin-top: -265px;
      display: flex;
      flex-direction: column;
      padding-left: 279px;

      .title {
        padding-top: 52px;
        margin-bottom: 50px;
        h2 {
          font: normal normal bold 100px/100px Poppins;
          letter-spacing: 0px;
          color: #00B7FF;
        }
      }

      .desc-text {
        width: 50%;
        font: normal normal normal 28px/42px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
      }

      .under-desc {
        font: normal normal bold 37px/56px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding-top: 28px;
        margin-bottom: 65px;
      }
    }
  }
}
