section.contact-header {
  margin-top: inRem(159);
  .container {
    display: flex;
    min-height: calc(100vh - #{inRem(159)});

    .left-info {
      @include leftSocial;
    }
  }

  .right-banner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{inRem(159)});
    width: calc(100% - #{inRem(165)});
    overflow: hidden;

    .return-wrapper {
      height: 184px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        text-decoration: none;
        font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-medium) 12px/18px
        var(--unnamed-font-family-poppins);
        color: var(--unnamed-color-484848);
        text-align: left;
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }
    }

    .title-wrapper {
      height: 140px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 143px;

      .section-title {
        padding-left: 108px;
        h1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-026cf5);
        }
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .show-parts {

        }

        .tel-info {
          margin-left: 108px;
          span.info-line {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--unnamed-color-026cf5);
            border-radius: 41px;
            width: 342px;
            height: 60px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
          }
        }
      }
    }

    .image-banner {
      position: relative;

      .square-1 {
        position: absolute;
        width: 52%;
        height: 38px;
        right: 0;
        bottom: calc(36% + 44px);
        background-color: #00b7ff;
      }

      .square-2 {
        position: absolute;
        width: 52%;
        height: 36%;
        right: 0;
        bottom: 0;
        background-color: #00b7ff;

        .image-info {
          position: absolute;
          top: 50%;
          right: 10%;
        }
      }
    }

    .lead-info {
      display: flex;
      flex-direction: row;
      min-height: 600px;

      .text-title-col {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 48%;

        .text-all-wrapper {
          padding-left: 112px;
          display: flex;
          flex-direction: column;

          .text-title {
            width: min-content;

            h2.title-1 {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100) /
              var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-00b7ff);
              text-align: left;
              opacity: 0.9;
            }

            h2.title-2 {
              font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100) /
              var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-026cf5);
              text-align: left;
              opacity: 0.9;
            }
          }

          .text-desc {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-707070);
            text-align: left;
            margin-top: 55px;
          }
        }
      }

      .right-info-col {
        display: flex;
        justify-content: flex-end;
        width: 52%;
        position: relative;

        .square {
          position: absolute;
          width: 100%;
          height: 36%;
          right: 0;
          top: 0;
          background-color: #00b7ff;
          z-index: -1;
        }
      }
    }
  }
}
