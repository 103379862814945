section.error-404 {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-00b7ff);
    text-align: left;
    font: normal normal bold 100px/100px Poppins;
    letter-spacing: 0px;
    color: #00B7FF;
  }
}