section.image-text-white {
  .container {
    padding-top: 200px;
    padding-bottom: 180px;

    .cols {
      width: calc(100% - 273px);
      margin-left: 273px;
      display: flex;

      .left {
        width: calc(100% - 800px);

        .title {
          margin-bottom: 48px;

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            font: normal normal bold 100px/100px Poppins;
            letter-spacing: 0px;
            color: #00B7FF;
          }

          h3.title-2 {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            text-align: left;
            font: italic normal bold 100px/100px Playfair Display;
            letter-spacing: 0px;
            color: #026CF5;
          }
        }

        .desc-content {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-484848);
          text-align: left;
          font: normal normal normal 28px/42px Poppins;
          letter-spacing: 0px;
          color: #484848;
          margin-bottom: 190px;
          width: 80%;
        }

        .image {
          width: 80%;
        }
      }

      .right {
        width: 800px;

        .top-image {
          display: flex;
          justify-content: center;
          background: var(--unnamed-color-00b7ff);

          .image-cnt {
            margin-bottom: -260px;
            margin-top: 120px;
            width: 55%;
            img {

            }
          }
        }
        .small-title {
          font-weight: var(--unnamed-font-weight-medium);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
          font-family: Poppins;
          font-size: 25px;
          line-height: 28px;
          letter-spacing: 0px;
          color: #00B7FF;
          margin-top: 300px;
          margin-bottom: 41px;
        }
        .ul-list {
          padding-right: 200px;

          .list-element {
            display: flex;
            font-weight: var(--unnamed-font-weight-medium);
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            font-family: Poppins;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 0px;
            color: #484848;
            opacity: 1;
            margin-bottom: 28px;

            .text {
              width: calc(100% - 92px);
            }

            &:before {
              content: "";
              display: block;
              width: 72px;
              margin-right: 20px;
              height: 3px;
              background-color: var(--unnamed-color-00b7ff);
              margin-top: 14px;
            }
          }
        }
      }
    }

    .cols-2 {
      width: calc(100% - 273px);
      margin-left: 273px;
      display: flex;

      .left {
        width: calc(100% - 800px);

        .small-title {
          font-weight: var(--unnamed-font-weight-medium);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
          font-family: Poppins;
          font-size: 25px;
          line-height: 28px;
          letter-spacing: 0px;
          color: #00B7FF;
          margin-bottom: 41px;
        }

        .ul-list {
          padding-right: 41px;

          .list-element {
            display: flex;
            font-weight: var(--unnamed-font-weight-medium);
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            font-family: Poppins;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 0px;
            color: #484848;
            opacity: 1;
            margin-bottom: 28px;

            .text {
              width: calc(100% - 92px);
            }

            &:before {
              content: "";
              display: block;
              width: 72px;
              margin-right: 20px;
              height: 3px;
              background-color: var(--unnamed-color-00b7ff);
              margin-top: 14px;
            }
          }
        }
      }

      .right {
        width: 800px;
      }
    }
  }
}