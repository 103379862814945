section.invests {
  display: flex;
  justify-content: center;
  margin-bottom: 279px;

  .container-invests {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;

    .invest-row {
      display: flex;
      width: 100%;
      margin-top: 60px;
      background-color: #026CF5;
      height: 536px;

      .image {
        width: 408px;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      .content {
        width: calc(100% - 408px);

        .top-state {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 40px;
          margin-bottom: 48px;

          .location {
            display: flex;
            align-items: center;

            .icon {
              padding-left: 35px;
              padding-right: 35px;

              img {
                width: 58px;
              }
            }

            span.district {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: left;
            }
          }

          .state-name-wrapper {
            display: flex;
            align-items: center;
            padding-right: 37px;

            .state-name {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/20px var(--unnamed-font-family-poppins);
              color: var(--unnamed-color-ffffff);
              text-align: right;
            }

            .state-point {
              margin-left: 20px;
              span {
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                background: #FF6200;
              }
            }
          }
        }

        .middle-title {
          padding-left: 128px;
          .title {
            h2 {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 30px/40px var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: left;
              margin-bottom: 53px;
            }
          }
        }

        .bottom-text-link {
          display: flex;
          justify-content: space-between;
          padding-left: 128px;
          .desc-content {
            width: 398px;
            .text {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }

          .link-wrapper {
            padding-left: 66px;
            padding-right: 37px;
            align-self: flex-end;
          }
        }

      }

      &.even {
        flex-direction: row;
      }

      &.odd {
        flex-direction: row-reverse;
      }


    }
  }
}
