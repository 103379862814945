@mixin fontThin($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 100;
}

@mixin fontExtraLight($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 200;
}

@mixin fontLight($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 300;
}

@mixin fontRegular($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 400;
}

@mixin fontMedium($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 500;
}

@mixin fontSemiBold($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 600;
}

@mixin fontBold($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 700;
}

@mixin fontExtraBold($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 800;
}

@mixin fontBlack($size: 1rem, $style: normal) {
  font-family: "Poppins", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: 900;
}


@mixin fontMontserrat($size: 1rem, $style: normal, $weight: 400) {
  font-family: "Montserrat", sans-serif;
  font-size: $size;
  font-style: $style;
  font-weight: $weight;
}

@mixin fontPlayfair($size: 1rem, $style: normal, $weight: 400) {
  font-family: "Playfair Display", serif;
  font-size: $size;
  font-style: $style;
  font-weight: $weight;
}
