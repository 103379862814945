section.newses {
  min-height: calc(100vh - #{inRem(159)});
  background-color: #026CF5;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: inRem(165) 0 inRem(32) inRem(140);

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: inRem(147);

      .title {
        h2.title {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
          opacity: 0.9;
        }
      }

      .btn-wrapper {
        width: calc(26% + 36px);
      }
    }

    .newses-row {
      overflow: hidden;

      .slide-line {
        height: 660px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        //.grid-news {
        //  height: 660px;
        //  display: flex;
        //  flex-direction: column;
        //  flex-wrap: nowrap;
        //  margin-right: inRem(74);
        //
        //  .image{
        //    height: 320px;
        //    overflow: hidden;
        //    img {
        //      width: 100%;
        //    }
        //  }
        //
        //  .intro {
        //    display: flex;
        //    flex-direction: column;
        //    flex-wrap: nowrap;
        //    height: 280px;
        //    background-color: rgba($white, 0.9);
        //    width: 100%;
        //
        //    .text {
        //      padding: 40px 50px;
        //      font: normal normal normal 19px/30px Poppins;
        //      letter-spacing: var(--unnamed-character-spacing-0);
        //      color: var(--unnamed-color-484848);
        //      text-align: left;
        //    }
        //
        //    .more-btn {
        //      display: flex;
        //      justify-content: flex-end;
        //      padding: 0 50px;
        //    }
        //  }
        //}
      }
    }
  }
}
