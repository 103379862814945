a.link-blue-regular {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #026CF5;
  width: 303px;
  height: 73px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  opacity: 0.9;
  border-radius: 41px;
  text-decoration: none;

  span {
    margin-right: inRem(12);
  }

  img {
    height: 20px;
  }
}


a.link-white-alpha-regular {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 303px;
  height: 73px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  opacity: 0.9;
  border-radius: 41px;
  text-decoration: none;
  border: 1px solid #FFFFFF;

  span {
    margin-right: inRem(12);
  }

  img {
    height: 20px;
  }
}


a.link-blue-medium {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #026CF5;
  width: 230px;
  height: 55px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  opacity: 0.9;
  border-radius: 41px;
  text-decoration: none;

  span {
    margin-right: inRem(12);
  }

  img {
    height: 20px;
  }
}

button.blue-btn {
  background: var(--unnamed-color-026cf5) 0% 0% no-repeat padding-box;
  border-radius: 36px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  border: none;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
  padding-right: 33px;
}

button.blue-btn-medium {
  top: 389px;
  left: 998px;
  width: 342px;
  height: 60px;
  background: var(--unnamed-color-026cf5) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
  padding-right: 33px;
}
