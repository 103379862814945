section.our-promise {
  .container {
    .banner {
      position: relative;
      .text-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 65%;
        background-color: rgba(#026CF5, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          width: 62%;

          h2.title {
            margin-bottom: 117px;

            span {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
            }
            span.font-2 {
              font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
            }
          }
        }
      }
    }
  }
}
