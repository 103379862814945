section.progress {
  background-color: $blue-extra-dark;
  min-height: calc(100vh - #{inRem(159)});
  padding-left: 279px;

  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;

    .left {
      padding-top: 160px;
      width: calc(100% - 648px);
      height: 160vh;

      .content {
        h2.title-1 {
          font: var(--unnamed-font-style-normal) normal 800
            var(--unnamed-font-size-100) / var(--unnamed-line-spacing-100)
            var(--unnamed-font-family-montserrat);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
          opacity: 1;
        }

        h2.title-2 {
          font: var(--unnamed-font-style-italic) normal
            var(--unnamed-font-weight-bold) var(--unnamed-font-size-100) / 133px
            var(--unnamed-font-family-playfair-display);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
          opacity: 1;
        }

        .text {
          margin-top: 48px;
          font: var(--unnamed-font-style-normal) normal
            var(--unnamed-font-weight-normal) var(--unnamed-font-size-28) /
            var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
          opacity: 1;
        }
      }

      .content-icons {
        margin-top: 100px;
        .icons-group {
          margin-top: 100px;
          h3 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            opacity: 1;
          }

          .icons-list {
            margin-top: 56px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 56px;

            .icon-el {
              display: flex;
              flex-direction: column;
              align-items: center;

              img {

              }

              .description {
                margin-top: 20px;
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-ffffff);
                text-align: center;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .right {
      width: 648px;
      display: flex;
      flex-direction: column;

      .right-top-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .square-blue-light {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 50%;
          width: 100%;
          background-color: #00b7ff;
          z-index: 1;
        }

        img {
          width: 565px;
          z-index: 2;
          align-self: flex-end;
          margin-bottom: 83px;
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}
