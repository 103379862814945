section.section-header {
  &.section-bussines {
    .right-banner {
      .image-banner {
        .square-2 {
          .image-info {
            .icons-row {
              display: flex;
              margin-top: 76px;
              height: 300px;

              .el-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 38px;

                .image {
                  margin-bottom: 65px;
                }

                .text-big {
                  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 107px/43px var(--unnamed-font-family-poppins);
                  letter-spacing: var(--unnamed-character-spacing-0);
                  color: var(--unnamed-color-00b7ff);
                  text-align: center;
                  margin-bottom: 65px;
                }

                .text-small {
                  font-weight: var(--unnamed-font-weight-medium);
                  letter-spacing: var(--unnamed-character-spacing-0);
                  color: var(--unnamed-color-00b7ff);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 25px;
                  line-height: 28px;
                  letter-spacing: 0px;
                  color: #484848;
                }
              }
            }
          }
        }
      }

      .lead-info {
        display: flex;
        flex-direction: column;
        padding-bottom: 300px;
        min-height: unset;

        .text-title {
          margin-top: 165px;
        }

        .desc-row {
          width: 48%;
          display: flex;
          margin-left: 7.5%;
          margin-top: 76px;
          .text {
            width: 100%;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-707070);
            text-align: left;
          }
        }
      }
    }
  }
}
