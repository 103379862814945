.container.politic-content {
  .row-content {
    margin-left: calc(6.5% + 165px);
    margin-top: 80px;
    margin-bottom: 140px;
    .col {
      padding-right: 10%;

      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
    }
  }
}