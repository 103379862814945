:root {

  /* Colors: */
  --unnamed-color-ff6200: #FF6200;
  --unnamed-color-707070: #707070;
  --unnamed-color-026cf5: #026CF5;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-efefef: #EFEFEF;
  --unnamed-color-0049a7: #0049A7;
  --unnamed-color-00f5ff: #00F5FF;
  --unnamed-color-196ddb: #196DDB;
  --unnamed-color-00b7ff: #00B7FF;
  --unnamed-color-484848: #484848;
  --unnamed-color-2b2e34: #2B2E34;

  /* Font/text values */
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-family-playfair-display: Playfair Display;
  --unnamed-font-family-montserrat: Montserrat;
  --unnamed-font-style-normal: normal;
  --unnamed-font-style-italic: italic;
  --unnamed-font-weight-300: 300;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-28: 28px;
  --unnamed-font-size-35: 35px;
  --unnamed-font-size-100: 100px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-28: 28px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-42: 42px;
  --unnamed-line-spacing-63: 63px;
  --unnamed-line-spacing-100: 100px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}
.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-25);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
}
.poppins-—-20pt-text-akt- {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-2b2e34);
}
.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-300);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-28);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-4 {
  font-family: var(--unnamed-font-family-montserrat);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-42);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-484848);
}
.unnamed-character-style-5 {
  font-family: var(--unnamed-font-family-playfair-display);
  font-style: var(--unnamed-font-style-italic);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-100);
  line-height: var(--unnamed-line-spacing-100);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-100);
  line-height: var(--unnamed-line-spacing-100);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-7 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-300);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-63);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-484848);
}
.unnamed-character-style-8 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-28);
  line-height: var(--unnamed-line-spacing-42);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-2b2e34);
}
