.grid-news {
  height: 660px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: inRem(74);

  .image{
    height: 320px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 280px;
    background-color: rgba($white, 0.9);
    width: 100%;

    .text {
      padding: 40px 50px;
      font: normal normal normal 19px/30px Poppins;
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-484848);
      text-align: left;
    }

    .more-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 50px;
    }
  }
}