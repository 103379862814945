section.buy-area {
  background-color: var(--unnamed-color-0049a7);

  .container {
    .cols {
      display: flex;
      margin-left: 152px;

      .left {
        width: calc(100% - 655px);
        margin-right: 80px;

        .title {
          width: 90%;
          padding-left: 10%;
          margin-bottom: 83px;
          margin-top: 63px;

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            font: normal normal bold 100px/100px Poppins;
            letter-spacing: 0px;
            color: #00B7FF;
          }

          h3.title-2 {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: italic normal bold 100px/100px Playfair Display;
            letter-spacing: 0px;
            color: #FFFFFF;
          }
        }

        .text-big {
          width: 90%;
          padding-left: 10%;
          margin-bottom: 83px;
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 40px/60px var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
          font: normal normal normal 40px/60px Poppins;
          letter-spacing: 0px;
          color: #FFFFFF;
        }

        .icon-text {
          display: flex;
          margin-bottom: 83px;

          .icon {
            width: 30%;
          }

          .text {
            width: calc(70% - 75px);
            margin-left: 75px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: normal normal normal 28px/42px Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
          }

          &.reverse {
            .text {
              margin-right: 75px;
            }
          }
        }
      }

      .right {
        padding-top: 600px;
        width: 735px;

        .image-cnt {
          background-color: var(--unnamed-color-00b7ff);

          img {
            margin-top: -280px;
            margin-bottom: 80px;
            margin-left: 80px;
            max-width: calc(100% - 80px);
          }
        }
      }
    }

    .cols-contact {
      margin-left: 225px;
      padding-bottom: 180px;
    }
  }
}