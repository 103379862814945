footer {
  min-height: 246px;
  padding-left: 168px;
  padding-right: 168px;
  background: #00B7FF;

  .container {
    display: flex;
    flex-direction: column;

    .top-wrapper {
      display: flex;
      align-items: center;
      margin-top: 66px;

      .foot-el {

        &.foot-el-1 {
          margin-right: 36px;
        }

        &.foot-el-2 {
          width: 100px;
          height: 1px;
          background-color: rgba($white, 0.8);
        }

        &.foot-el-3 {
          margin-left: 36px;
        }

        &.foot-el-4-menu {
          display: flex;
          align-items: center;
          margin-left: 56px;

          .link{
            margin-right: 36px;

            &:last-child {
              margin-right: 0;
            }

            a {
              text-decoration: none;
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) 21px/66px var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: center;
              opacity: 1;
            }
          }
        }

        &.foot-el-5-social {
          display: flex;
          align-items: center;
          margin-left: 56px;

          .soc-link {
            margin-right: 12px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .bottom-wrapper {
      margin-top: inRem(32);
      display: flex;
      justify-content: flex-end;

      span.info-line {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 41px;
        width: 342px;
        height: 60px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
      }
    }
  }
}
