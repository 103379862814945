@mixin leftSocial {
  display: block;
  width: inRem(165);
  position: relative;

  .info-wrapper {
    display: flex;
    //align-items: center;
    //position: absolute;
    //left: 0;
    //top: calc(50% - 159px);
    width: inRem(165);
    height: 100%;
    flex-direction: column;

    .container-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: column;

      .data-wrapper{
        transform: rotate(-90deg);
        transform-origin: 169px 164px;

        .content-cnt {
          display: flex;
          flex-wrap: nowrap;
          width: inRem(24);

          span.email {
            display: block;
            white-space: nowrap;
            padding-left: inRem(20);
          }

          span.phone {
            display: block;
            white-space: nowrap;
            padding-right: inRem(12);
            padding-left: inRem(20);
          }

        }
      }

      .line {
        height: 100px;
        width: 1px;
        margin-top: 24px;
        background-color: var(--unnamed-color-484848);
      }

      .social {
        display: flex;
        flex-direction: column;

        .icon-sc {
          margin-top: inRem(12);
        }
      }
    }
  }
}
