section.contact-form-section {
  margin-top: 54px;
  margin-bottom: 54px;

  .container {
    padding-left: 276px;
    padding-right: 276px;
    display: flex;

    .left {
      width: calc(100% / 3);
      border-bottom: 1px solid $gray;

      .title {
        margin-bottom: 75px;

        h2.title-1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
        }

        h2.title-2 {
          font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-026cf5);
          text-align: left;
        }
      }

      .info-contact {
        position: relative;
        height: 380px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          height: 100%;
          width: 1px;
          background-color: $gray;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          height: 100%;
          width: 1px;
          background-color: $gray;
        }

        .info-all-wrapper {
          .info-wrapper {
            margin-bottom: 53px;

            .elem-info {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-707070);
            }
          }
        }
      }
    }

    .right {
      width: calc((100% / 3) * 2);
      border-bottom: 1px solid $gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
