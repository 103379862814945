section.flats-intro {
  .container {
    .cols {
      display: flex;
      margin-left: 165px;
      width: calc(100% - #{inRem(165)});
      .left {
        width: 48%;
        display: flex;
        justify-content: center;
        .text {
          width: 70%;
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-2b2e34);
          text-align: left;
          opacity: 0.9;
          margin-bottom: inRem(55);
        }
      }

      .right {
        width: calc(47% - 3px);

        .icon-list {
          display: flex;
          justify-content: flex-end;
          margin-right: 10%;

          .col-el {
            display: flex;
            flex-direction: column;
            align-items: center;


            &:first-child {
              margin-right: 97px;

              .icon {
                img {
                  height: 150px;
                  margin-bottom: -30px;
                }
              }
            }

            &:last-child {
              .icon {
                img {
                  height: 100px;
                }
              }
            }

            .icon {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              width: 60%;
              height: 200px;
            }

            .number {
              font: normal normal bold 107px/43px Poppins;
              letter-spacing: 0px;
              padding-top: 48px;
              padding-bottom: 48px;

              &.blue {
                color: #026CF5;
              }

              &.blue-light {
                color: #00B7FF;
              }
            }

            .text {
              font-weight: var(--unnamed-font-weight-medium);
              letter-spacing: var(--unnamed-character-spacing-0);
              text-align: left;
              font-family: Poppins;
              font-size: 25px;
              line-height: 28px;
              letter-spacing: 0px;
              color: #484848;
              opacity: 1;
              text-align: center;
            }
          }
        }
      }
    }
  }
}