section.header-invest {
  margin-top: inRem(159);

  .container {
    display: flex;
    min-height: calc(100vh - #{inRem(159)});

    .left-info {
      @include leftSocial;
    }


    .right-banner {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - #{inRem(159)});
      width: calc(100% - #{inRem(165)});
      overflow: hidden;

      .return-wrapper {
        height: 184px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
          text-decoration: none;
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 12px/18px var(--unnamed-font-family-poppins);
          color: var(--unnamed-color-484848);
          text-align: left;
          display: flex;
          align-items: center;

          img {
            margin-right: 20px;
          }
        }
      }

      .title-wrapper {
        margin-bottom: 58px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .section-title {
          padding-left: 108px;
          width: 70%;

          h1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
          }
        }
      }

      .image-banner {
        position: relative;

        .square-1 {
          position: absolute;
          width: 52%;
          height: 38px;
          right: 0;
          bottom: calc(36% + 44px);
          background-color: #00b7ff;
        }

        .square-2 {
          position: absolute;
          width: 52%;
          height: 36%;
          right: 0;
          bottom: 0;
          background-color: #00b7ff;

          .image-info {
            position: absolute;
            top: 50%;
            right: 10%;
          }
        }
      }

      .lead-info {
        display: flex;
        flex-direction: row;
        min-height: 600px;

        .text-col {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48%;

          .text-desc {
            padding-left: 114px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-707070);
            text-align: left;
          }
        }

        .right-info-col {
          display: flex;
          justify-content: flex-end;
          width: 52%;
          position: relative;

          .square {
            position: absolute;
            width: 100%;
            height: 36%;
            right: 0;
            top: 0;
            background-color: #00b7ff;
            z-index: -1;
          }
        }
      }
    }
  }

  .container-content-invest {
    margin-left: inRem(165);
    position: relative;
    z-index: 2;

    .params-details {
      background-color: #026CF5;
      width: 48%;
      padding-bottom: 68px;

      .top-state {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        margin-bottom: 48px;
        padding-left: 154px;

        .location {
          display: flex;
          align-items: center;

          .icon {
            padding-left: 35px;
            padding-right: 35px;

            img {
              width: 58px;
            }
          }

          span.district {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
          }
        }

        .state-name-wrapper {
          display: flex;
          align-items: center;
          padding-right: 37px;

          .state-name {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/20px var(--unnamed-font-family-poppins);
            color: var(--unnamed-color-ffffff);
            text-align: right;
          }

          .state-point {
            margin-left: 20px;
            span {
              display: block;
              width: 40px;
              height: 40px;
              border-radius: 100px;
              background: #FF6200;
            }
          }
        }
      }

      .middle-title {
        padding-left: 217px;
        .title {
          h2 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 30px/40px var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
            margin-bottom: 53px;
          }
        }
      }

      .table-details {
        color: $white;
        padding-left: 217px;
        padding-right: 53px;

        .row-details {
          display: flex;
          width: 100%;
          min-height: 39px;
          .col1 {
            display: flex;
            align-items: center;
            margin-right: 33px;
            width: 30px;
            img {
              width: 22px;
            }
          }

          .col2 {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $white;
            width: calc(100% - 263px);
          }

          .col3 {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $white;
            width: 200px;
          }
        }
      }

    }
    .other-details {

    }
  }

  .image-under {
    position: relative;
    z-index: 1;
    padding-left: 278px;
    padding-right: 278px;
    margin-bottom: 98px;

    img {
      margin-top: -98px;
    }
  }

  .under-content {
    padding-left: 278px;
    margin-bottom: 76px;

    .text-wrapper {
      width: 48%;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      text-align: left;
    }
  }

  .invest-offices {
    padding-left: 278px;
    padding-right: 278px;
    .title {
      margin-bottom: 98px;

      h2.title-1 {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-00b7ff);
        text-align: left;
      }

      h2.title-2 {
        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-026cf5);
        text-align: left;
      }
    }

    //.row-invest-offices {
    //  display: flex;
    //  width: 100%;
    //
    //  .col {
    //    width: calc(100% / 3);
    //    border-bottom: 1px solid $gray;
    //    padding-left: 53px;
    //    padding-right: 53px;
    //    padding-bottom: 53px;
    //
    //    &:first-child {
    //      position: relative;
    //      &:after {
    //        content: "";
    //        display: block;
    //        height: 100%;
    //        width: 1px;
    //        background-color: $gray;
    //        position: absolute;
    //        right: 0;
    //        top: 0;
    //      }
    //    }
    //
    //    &:last-child {
    //      position: relative;
    //      &:before{
    //        content: "";
    //        display: block;
    //        height: 100%;
    //        width: 1px;
    //        background-color: $gray;
    //        position: absolute;
    //        left: 0;
    //        top: 0;
    //      }
    //    }
    //
    //    .name {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //      margin-bottom: 53px;
    //    }
    //
    //    .address {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //    }
    //
    //    .phone {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //      margin-top: 83px;
    //    }
    //
    //    .person-name {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //      margin-bottom: 53px;
    //    }
    //
    //    .email {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //    }
    //
    //    .person-phone {
    //      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
    //      letter-spacing: var(--unnamed-character-spacing-0);
    //      color: var(--unnamed-color-707070);
    //    }
    //
    //    .form-btn-wrapper {
    //      margin-top: 53px;
    //    }
    //  }
    //}
  }

  .invest-location {
    padding-left: 278px;
    padding-right: 278px;
    margin-top: 53px;

    .title {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-00b7ff);
      margin-bottom: 33px;
    }
  }

}
