.contact-form {
  width: 73%;
  form {
    .form-all-wrapper {
      display: flex;
      flex-direction: column;

      .input-row {
        width: 100%;
        margin-bottom: 20px;

        input, textarea {
          background: $white-gray;
          width: 100%;
          border: none;
        }

        input {
          height: 50px;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &:-moz-placeholder { /* Firefox 18- */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
        }

        textarea {
          height: 114px;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
          &:-moz-placeholder { /* Firefox 18- */
            font: normal normal normal 12px/30px Poppins;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            padding-left: 20px;
            height: 50px;
          }
        }

        label {
          display: flex;

          .checkbox-btn {
            width: 29px;
            margin-right: 25px;
            .square-check {
              height: 27px;
              width: 27px;
              border: 1px solid #484848;
              position: relative;

              &.active {
                background: var(--unnamed-color-026cf5);

                &:before {
                  content: "";
                  display: block;
                  background-color: var(--unnamed-color-ffffff);
                  width: 1px;
                  height: 17px;
                  transform: rotate(45deg);
                  position: absolute;
                  left: 12px;
                  top: 4px;
                }

                &:after {
                  content: "";
                  display: block;
                  background-color: var(--unnamed-color-ffffff);
                  width: 1px;
                  height: 17px;
                  transform: rotate(-45deg);
                  position: absolute;
                  left: 12px;
                  top: 4px;
                }
              }
            }
          }

          .label-text {
            width: calc(100% - 52px);
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) 12px/12px var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-484848);
            text-align: left;
          }
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 32px;
      }
    }
  }
}
