section.about {
  min-height: calc(100vh - #{inRem(159)});

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: calc(100% - 165px);
      margin-left: 165px;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39%;

        .info-wrapper {

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            opacity: 0.9;

          }

          h2.title-2 {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            text-align: left;
            opacity: 0.9;
          }

          .text {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-2b2e34);
            text-align: left;
            opacity: 0.9;
            margin-top: inRem(55);
          }
        }
      }

      .right {
        position: relative;
        height: 100%;
        width: 61%;
        display: flex;
        justify-content: flex-end;

        .square-blue {
          position: absolute;
          right: 0;
          top: 0;
          height: 50%;
          width: 100%;
          background-color: #00B7FF;
          z-index: 1;
        }

        img {
          z-index: 2;
        }
      }

    }
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .left-info {
      display: block;
      width: inRem(165);
    }
    .icons-list {

    }
    .btn-col {
      margin-top: 32px;
      padding-bottom: 32px;
      width: 26%;
    }
  }
}
