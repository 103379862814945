section.news-relations-section {
  .container {
    .rel-newses-wrapper {
      margin-right: 279px;
      margin-left: calc(3% + 165px);

      .title {
        margin-bottom: 45px;
        h2 {
          font: normal normal bold 30px/30px Poppins;
          letter-spacing: 0px;
          color: #026CF5;
        }
      }

      .rel-newses-list-body{
        form.search-input {
          display: flex;
          width: 100%;

          button.input-loupe {
            border: none;
            height: 110px;
            width: 110px;
            background: #026CF5;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }

          .input-control {
            display: flex;
            align-items: center;
            background: #EFEFEF;
            height: 110px;
            width: calc(100% - 120px);
            padding-left: 40px;

            input {
              height: 110px;
              width: 100%;
              background: #EFEFEF;
              border: none;
              font: normal normal normal 20px/30px Poppins;
              letter-spacing: 0px;
              color: #707070;

              &:focus {
                outline: none;
              }

              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font: normal normal normal 20px/30px Poppins;
                letter-spacing: 0px;
                color: #707070;
                height: 110px;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                font: normal normal normal 20px/30px Poppins;
                letter-spacing: 0px;
                color: #707070;
                height: 110px;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                font: normal normal normal 20px/30px Poppins;
                letter-spacing: 0px;
                color: #707070;
                height: 110px;
              }
              &:-moz-placeholder { /* Firefox 18- */
                font: normal normal normal 20px/30px Poppins;
                letter-spacing: 0px;
                color: #707070;
                height: 110px;
              }
            }
          }
        }

        .list {

        }
      }
    }
  }
}