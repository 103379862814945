section.first-relations-section {
  .container {
    .row {
      display: flex;
      margin-left: calc(3% + 165px);
      .left {
        width: 518px;
        margin-right: 172px;

        .desc-text {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-707070);
        }
      }

      .right {
          width: calc(100% - 690px);
          padding-right: 279px;

        .row-open-list {
          .list-element {
            margin-bottom: 20px;

            .list-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: #EFEFEF;

              .icon {
                width: 161px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .text {
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-707070);
              }

              .btns-el {
                padding: 26px;

                img {
                  cursor: pointer;
                }
                &.open {
                  img {
                    transform: rotate(45deg);
                  }
                }
              }
            }

            .list-desc {
              background: #026CF5;
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-25) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              padding: 34px 34px 34px 161px;

              &.close {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}