.drop-menu {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: 1000ms top;

  &.open-menu {
    top: 0vh;
    transition: 1000ms top;
  }

  .menu-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .top-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: inRem(165);
      width: 100%;
      height: inRem(159);

      .left-logo {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        h1 {
          a {
            img {
              width: inRem(236);
            }
          }
        }
      }

      .right-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 122px;
        height: 122px;
        padding-right: inRem(53);
      }
    }

    .bottom-wrapper {
      width: 100%;
      height: calc(100vh - #{inRem(159)});
      padding-left: inRem(165);
      padding-right: inRem(115);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tree-menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: inRem(75);

        .col-menu {
          .title-menu {
            text-decoration: none;
            font-family: var(--unnamed-font-family-poppins);
            font-style: var(--unnamed-font-style-normal);
            font-weight: var(--unnamed-font-weight-bold);
            font-size: var(--unnamed-font-size-20);
            line-height: var(--unnamed-line-spacing-63);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-484848);
            margin-bottom: inRem(26);
          }

          ul {
            li {
              margin-bottom: inRem(24);

              a {
                text-decoration: none;
                font-family: var(--unnamed-font-family-poppins);
                font-style: var(--unnamed-font-style-normal);
                font-weight: var(--unnamed-font-weight-300);
                font-size: var(--unnamed-font-size-20);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-484848);
              }
            }
          }
        }
      }

      .bottom-row {
        width: 100%;
        padding-bottom: inRem(30);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;

        .left-bottom-col{
          display: flex;
          align-items: center;

          ul {
            li {
              a {
                text-decoration: none;
                font-family: var(--unnamed-font-family-poppins);
                font-style: var(--unnamed-font-style-normal);
                font-weight: var(--unnamed-font-weight-bold);
                font-size: var(--unnamed-font-size-20);
                line-height: var(--unnamed-line-spacing-30);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-484848);
              }
            }
          }
        }

        .right-bottom-col {
          span.info-line {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--unnamed-color-026cf5);
            border-radius: 41px;
            width: 467px;
            height: 82px;
            font-family: var(--unnamed-font-family-poppins);
            font-style: var(--unnamed-font-style-normal);
            font-weight:  var(--unnamed-font-weight-bold) ;
            font-size: 25px;
            line-height: var(--unnamed-line-spacing-28);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
          }
        }

      }

    }
  }
}
