nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: inRem(159);
  background-color: rgba($white, 0.9);
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left {
    padding-left: inRem(165);

    h1 {
      a {
        img {
          width: inRem(236);
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .menu {
      ul {
        display: flex;
        li {
          margin-right: inRem(54);
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            font-family: var(--unnamed-font-family-poppins);
            font-style: var(--unnamed-font-style-normal);
            font-weight: var(--unnamed-font-weight-300);
            font-size: var(--unnamed-font-size-20);
            line-height: 0;
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-484848);

            img {
              width: 40px;
            }
          }
        }
      }
    }

    .menu-btn {
      width: 122px;
      height: 122px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: inRem(53);

      img {
        cursor: pointer;
      }
    }
  }
}
