section.flats-about {
  background: var(--unnamed-color-026cf5);
  .container {
    .cols-top {
      display: flex;

      &:before {
        content: "";
        height: auto;
        display: block;
        width: 165px;
        background: var(--unnamed-color-00b7ff);
      }

      .left {
        background: var(--unnamed-color-00b7ff);
        height: auto;
        width: 52%;
        display: flex;
        justify-content: flex-end;

        .content {
          color: $white;
          width: 87%;

          .text-over {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: italic normal normal 28px/42px Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-top: inRem(75);
            margin-bottom: inRem(55);
          }

          .person-info {
            display: flex;

            .image {
              width: calc(50% + 89px);
              margin-right: 89px;
              img {
                margin-bottom: -100px;
              }
            }

            .person-text {
              width: fit-content;
              padding-right: 55px;

              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: left;
              font: normal normal bold 20px/28px Poppins;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }

      .right {
        width: calc(38% - 3px);
      }
    }

    .cols-bottom {
      display: flex;
      padding-bottom: 92px;

      &:before {
        content: "";
        height: auto;
        display: block;
        width: 165px;
      }

      .left {
        width: 52%;
        display: flex;
        justify-content: flex-end;

        .title-bg-wrapper {
          margin-top: 254px;
          width: 87%;

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            font: normal normal bold 100px/100px Poppins;
            letter-spacing: 0px;
            color: #00B7FF;
          }
          h3.title-2 {
            font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;
            font: italic normal bold 100px/100px Playfair Display;
            letter-spacing: 0px;
            color: #FFFFFF;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        width: fit-content;
      }
    }

    .cols-icons {
      display: flex;
      justify-content: space-between;
      padding-bottom: 183px;

      &:before {
        content: "";
        height: auto;
        display: block;
        width: 165px;
      }

      .grid-icons-three {
        width: 85%;
        .icons-grid-cnt {
          width: 70%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 138px;

          .icon-col {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #FFFFFF;

            .icon {
              margin-bottom: 32px;
            }

            .text {
              font-style: var(--unnamed-font-style-normal);
              font-weight: var(--unnamed-font-weight-medium);
              font-size: 25px;
              line-height: var(--unnamed-line-spacing-28);
              font-family: var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: center;
              font: normal normal medium 25px/28px Poppins;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}