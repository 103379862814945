.row-invest-offices-blue {
  display: flex;
  width: 100%;

  .col {
    width: calc(100% / 3);
    border-bottom: 1px solid #FFFFFF;
    padding-left: 53px;
    padding-right: 53px;
    padding-bottom: 53px;

    &:first-child {
      position: relative;
      &:before {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &:last-child {
      position: relative;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .title {
      font-weight:var(--unnamed-font-weight-bold);
      font-size: 30px;
      line-height: 30px;
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-00b7ff);
      text-align: left;
      font-family: Poppins;
      letter-spacing: 0px;
      color: #00B7FF;
      margin-bottom: 50px;
      margin-top: 14px;
    }

    .name-person {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-ffffff);
      text-align: left;
      margin-bottom: 50px;
      .name {

      }

      .position {

      }
    }

    .data {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-ffffff);
      text-align: left;

      .email {

      }

      .phone {

      }
    }
  }
}
