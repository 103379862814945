section.attachments-relations-section {
  margin-top: 88px;
  .container {
    .attachments-list-wrapper {
      margin-right: 279px;
      margin-left: calc(3% + 165px);

      .attachments-list {
        margin-bottom: 75px;

        .title {
          margin-bottom: 34px;
          h2 {
            font: normal normal bold 30px/30px Poppins;
            letter-spacing: 0px;
            color: #026CF5;
          }
        }

        .table-head {
          border-top: 1px solid #707070;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font: normal normal bold 16px/25px Poppins;
          letter-spacing: 0px;
          color: #707070;

          .label-dwn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 67px;
          }

          .labels-right {
            display: flex;
            align-items: center;
            width: 242px;
            height: 67px;

            .label-type {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 121px;
            }

            .label-size {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 121px;
            }
          }
        }

        .table-body {
          display: flex;
          flex-direction: column;
          width: 100%;

          .attach-element {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            a.btn-dwn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 110px;
              height: 110px;
              margin-right: 10px;
              background: #026CF5;
              cursor: pointer;

              &:hover {
                background: #FF6200;
              }
            }

            .attach-title {
              display: flex;
              align-items: center;
              width: calc(100% - 362px);
              padding-left: 57px;
              background: #EFEFEF;
            }

            .attach-right {
              display: flex;
              align-items: center;
              width: 242px;
              height: 110px;
              background: #EFEFEF;

              .type {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 121px;
              }

              .size {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 121px;
                font: normal normal bold 20px/30px Poppins;
                letter-spacing: 0px;
                color: #026CF5;
              }
            }
          }
        }
      }
    }
  }
}