section.bussines-intro-third {
  background: #0049A7;
  padding-bottom: 170px;

  .container {
    .text-row {
      margin-left: 178px;
      padding-left: 101px;
      background-color: #196DDB;
      display: flex;

      .desc {
        font: normal normal normal 28px/42px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 554px;
        padding-top: 85px;
      }

      .image {
        margin-left: 37px;
        margin-right: 138px;
        padding-top: 85px;
        margin-bottom: -85px;
      }
    }

    .second-row {
      display: flex;
      padding-bottom: 200px;

      .info-person-col {
        padding-left: 279px;
        margin-top: 170px;
        background: #00B7FF;
        width: calc(48% + 170px);

        .person-info {
          padding-top: 75px;
          padding-right: 89px;

          .text {
            font: italic normal normal 28px/42px Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-bottom: 75px;
          }

          .photo-desc {
            display: flex;


            .photo {
              width: 70%;
              img {
                margin-bottom: -75px;
              }
            }

            .desc {
              font: normal normal bold 20px/28px Poppins;
              letter-spacing: 0px;
              color: #FFFFFF;
              margin-left: 89px;
            }
          }
        }
      }

      .image-right {
        padding-bottom: -50%;
        width: calc(52% - 242px);
        position: relative;


        img {
          position: absolute;
          bottom: -300px;
          right: 72px;
          max-width: 130%;
          min-width:  130%;
        }
      }
    }

    .third-row {
      margin-left: 279px;
      .title-col {
        h2.title-1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
        }

        h3.title-2 {
          font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
        }
      }

      .grid-icons-three {
        margin-top: 114px;
        width: 100%;

        .icons-grid-cnt {
          width: 70%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 138px;

          .icon-col {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #FFFFFF;

            .icon {
              margin-bottom: 32px;
            }

            .text {
              font-style: var(--unnamed-font-style-normal);
              font-weight: var(--unnamed-font-weight-medium);
              font-size: 25px;
              line-height: var(--unnamed-line-spacing-28);
              font-family: var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              color: var(--unnamed-color-ffffff);
              text-align: center;
              font: normal normal medium 25px/28px Poppins;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }
    }
    .fourth-row {
      margin-top: 248px;
      margin-left: 279px;
      display: flex;

      .map-title-col {
        width: 310px;
        padding-left: 52px;
        border-left: 2px solid #00B7FF;

        h2.title {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 58px/62px var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
        }

        .map-legend {
          margin-top: 81px;

          .legend-el {
            display: flex;
            margin-bottom: 22px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: left;

            &.open {
              &:before {
                content: "";
                display: block;
                height: 22px;
                width: 22px;
                border-radius: 60px;
                background-color: #FFFFFF;
                margin-right: 17px;
              }
            }

            &.plan {
              &:before {
                content: "";
                display: block;
                height: 22px;
                width: 22px;
                border-radius: 60px;
                background-color: #FFE203;
                margin-right: 17px;
              }
            }

            &.inbuild {
              &:before {
                content: "";
                display: block;
                height: 22px;
                width: 22px;
                border-radius: 60px;
                background-color: #FF6200;
                margin-right: 17px;
              }
            }
          }
        }
      }

      .map-col {
        margin-left: 191px;
      }
    }
  }
}