$orange: #FF6200;
$gray: #707070;
$gray-darker: #484848;
$gray-extra: #2B2E34;

$blue-extra-dark: #0049A7;
$blue-dark: #196DDB;
$blue: #026CF5;
$blue-light: #00B7FF;
$blue-extra-light: #00F5FF;

$white: #FFFFFF;
$white-gray: #EFEFEF;




