section.icons-list-about {
  padding-bottom: 137px;

  .container {
    display: flex;

    &:before {
      content: "";
      height: auto;
      display: block;
      width: calc(6.5% + 165px);
    }
    .list {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-gap: 70px;
      width: 45%;
      .icon-element {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-bottom: 12px;
        }
        .number {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 50px/20px var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: center;
          font: normal normal bold 50px/20px Poppins;
          letter-spacing: 0px;
          color: #00B7FF;
          height: 91px;
          display: flex;
          align-items: center;
        }
        .text {
          font-weight: var(--unnamed-font-weight-medium);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: center;
          font-family: Poppins;
          font-size: 25px;
          line-height: 28px;
          letter-spacing: 0px;
          color: #484848;
        }
      }
    }
  }
}