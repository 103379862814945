section.home-lead {
  margin-top: inRem(159);
  .container {
    display: flex;
    //width: 100%;
    min-height: calc(100vh - #{inRem(159)});

    .left-info {
      @include leftSocial;
    }

    .right-banner {
      height: calc(100vh - #{inRem(159)});
      overflow: hidden;
      position: relative;
      width: calc(100% - #{inRem(165)});

      .titles-wrapper {
        position: absolute;
        top: 100px;
        left: 100px;

        h2.title-1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
          opacity: 0.9;
        }

        h2.title-2 {
          font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
          opacity: 0.9;
        }
      }

      img.img-fluid {
        min-height: calc(100vh - #{inRem(159)});
      }

      .square-1 {
        position: absolute;
        width: 61%;
        height: 38px;
        right: 0;
        bottom: calc(36% + 44px);
        background-color: #00B7FF;
      }

      .square-2 {
        position: absolute;
        width: 61%;
        height: 36%;
        right: 0;
        bottom: 0;
        background-color: #00B7FF;
      }
    }
  }
}
