section.contact-section {
  margin-top: 54px;

  .container {
    padding-left: 276px;
    padding-right: 276px;

    .title {
      margin-bottom: 98px;

      h2.title-1 {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-00b7ff);
        text-align: left;
      }

      h2.title-2 {
        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-026cf5);
        text-align: left;
      }
    }

    .contact-lists {
      .row-invest-offices {
        border-bottom: 1px solid $gray;

        .col {
          border-bottom: none;

          &:last-child {
            &:before{
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

