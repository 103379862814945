.row-invest-offices {
  display: flex;
  width: 100%;

  .col {
    width: calc(100% / 3);
    border-bottom: 1px solid $gray;
    padding-left: 53px;
    padding-right: 53px;
    padding-bottom: 53px;

    &:first-child {
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background-color: $gray;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &:last-child {
      position: relative;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        background-color: $gray;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .name {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      margin-bottom: 53px;
    }

    .address {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
    }

    .phone {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      margin-top: 83px;
    }

    .person-name {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
      margin-bottom: 53px;
    }

    .email {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
    }

    .person-phone {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-poppins);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-707070);
    }

    .form-btn-wrapper {
      margin-top: 53px;
    }
  }
}
