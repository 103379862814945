section.section-header {
  margin-top: inRem(159);

  .container {
    display: flex;
    min-height: calc(100vh - #{inRem(159)});

    .left-info {
      @include leftSocial;
    }
  }

  .right-banner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{inRem(159)});
    width: calc(100% - #{inRem(165)});
    overflow: hidden;

    .return-wrapper {
      height: 184px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        text-decoration: none;
        font: var(--unnamed-font-style-normal) normal
          var(--unnamed-font-weight-medium) 12px/18px
          var(--unnamed-font-family-poppins);
        color: var(--unnamed-color-484848);
        text-align: left;
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }
    }

    .title-wrapper {
      height: 140px;
      margin-bottom: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section-title {
        padding-left: 108px;
        h1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-026cf5);
        }
      }

      .section-subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 104px;

        .counter {
          font: normal normal 300 62px/28px Poppins;
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-026cf5);
          text-align: right;
          position: relative;
          padding-right: 28px;
          height: 81px;
          display: flex;
          align-items: center;

          &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            background-color: var(--unnamed-color-00b7ff);
          }
        }
        h2 {
          padding-left: 28px;
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 24px/26px var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-026cf5);
          text-align: left;
        }
      }
    }

    .image-banner {
      position: relative;

      .square-1 {
        position: absolute;
        width: 52%;
        height: 38px;
        right: 0;
        bottom: calc(36% + 44px);
        background-color: #00b7ff;

        &.less-w {
          width: 35%;
        }
      }

      .square-2 {
        position: absolute;
        width: 52%;
        height: 36%;
        right: 0;
        bottom: 0;
        background-color: #00b7ff;

        &.less-w {
          width: 35%;
        }

        .image-info {
          position: absolute;
          top: 50%;
          right: 10%;
        }
      }
    }

    .lead-info {
      display: flex;
      flex-direction: row;
      min-height: 600px;

      &.just-b{
        justify-content: space-between;
      }

      .text-title-col {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48%;

        &.more-w {
          width: 65%;
        }

        .text-title {
          width: 70%;

          h2.title-1 {
            font: var(--unnamed-font-style-normal) normal
              var(--unnamed-font-weight-bold) var(--unnamed-font-size-100) /
              var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: left;
            opacity: 0.9;
          }

          h2.title-2 {
            font: var(--unnamed-font-style-italic) normal
              var(--unnamed-font-weight-bold) var(--unnamed-font-size-100) /
              var(--unnamed-line-spacing-100)
              var(--unnamed-font-family-playfair-display);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-026cf5);
            text-align: left;
            opacity: 0.9;
          }
        }

        &.more-w {
          .text-title {
            width: 90%;
          }
        }

        &.with-description {
          display: flex;
          flex-direction: column;

          .text-title {
            margin-top: 125px;
          }

          .text-desc {
            width: 70%;
            margin-top: 84px;
            margin-bottom: 32px;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-707070);
            text-align: left;
            opacity: 0.9;
          }
        }

        &.more-w {
          .text-desc {
            width: 90%;
          }
        }
      }

      .right-info-col {
        display: flex;
        justify-content: flex-end;
        width: 52%;
        position: relative;

        &.less-w {
          width: 35%;
        }

        .square {
          position: absolute;
          width: 100%;
          height: 36%;
          right: 0;
          top: 0;
          background-color: #00b7ff;
          z-index: -1;
        }
      }
    }
  }
}
