section.image-text-blue {
  background: var(--unnamed-color-026cf5);

  .container {
    .title {
      padding-top: 52px;
      padding-bottom: 67px;
      margin-left: 265px;
      h2.title-1 {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-00b7ff);
        text-align: left;
        font: normal normal bold 100px/100px Poppins;
        letter-spacing: 0px;
        color: #00B7FF;
      }
      h3.title-2 {
        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: italic normal bold 100px/100px Playfair Display;
        letter-spacing: 0px;
        color: #FFFFFF;
      }
    }

    .text-description {
      display: flex;
      margin-left: 201px;
      width: calc(100% - 201px);
      background: var(--unnamed-color-0049a7);

      .image {
        margin-left: -201px;
        margin-bottom: -120px;
        padding-top: 180px;
      }

      .text {
        padding-left: 149px;
        padding-right: 177px;
        padding-top: 77px;
        height: auto;
        display: flex;
        font-weight: var(--unnamed-font-weight-medium);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: #FFFFFF;
        text-align: left;
        font-family: Poppins;
        font-size: 25px;
        line-height: 42px;
        letter-spacing: 0px;
      }
    }

    .icon-list {
      padding-top: 240px;
      margin-left: 265px;

      .icons-title {
        padding-bottom: 120px;
        h2 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
          font: normal normal bold 100px/100px Poppins;
          letter-spacing: 0px;
          color: #00B7FF;
        }
      }
      .icons {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 80px;
        padding-bottom: 200px;

        .icon-element {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            margin-bottom: 40px;
          }

          .text {
            font-weight: var(--unnamed-font-weight-medium);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-00b7ff);
            text-align: center;
            font-family: Poppins;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 0px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}