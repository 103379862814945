section.invest-intro {
    .container {
        .top-space {
            display: flex;

            .left {
                position: relative;
                width: 65%;
                height: 334px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    display: block;
                    height: 150px;
                    width: 100%;
                    background-color: var(--unnamed-color-0049a7);
                }
            }

            .right {
                position: relative;
                width: 35%;
                height: 334px;

                &:before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 38px;
                    width: 100%;
                    background-color: var(--unnamed-color-00b7ff);
                }
            }
        }
        .invest-flat {
            display: flex;
            .left {
                width: 65%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 200px;

                .text-wrapper {
                    width: 62%;

                    h2.title-1 {
                        font: var(--unnamed-font-style-normal) normal 800 var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-montserrat);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-00b7ff);
                        text-align: left;
                    }

                    h2.title-2 {
                        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-026cf5);
                        text-align: left;
                    }

                    .desc-text {
                        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-2b2e34);
                        text-align: left;
                        margin-top: 77px;
                    }

                    .btn-wrapper {
                        margin-top: 77px;
                        margin-bottom: 77px;
                    }
                }
            }

            .right {
                position: relative;
                width: 35%;

                img {
                    width: 100%;
                }
            }
        }
        .invest-bussines {
            margin-top: -200px;
            min-height: 600px;
            background-color: var(--unnamed-color-026cf5);

            .title-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 35%;
                padding-top: 200px;
                margin-bottom: 56px;


                .content-wrapper {
                    width: 62%;
                    h2.title-1 {
                        font: var(--unnamed-font-style-normal) normal 800 var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-montserrat);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-00b7ff);
                        text-align: left;
                    }

                    h2.title-2 {
                        font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/133px var(--unnamed-font-family-playfair-display);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-ffffff);
                        text-align: left;
                    }
                }
            }

            .content-cols {
                display: flex;
                align-items: center;
                justify-content: center;

                .left {
                    width: 65%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .content-wrapper {
                        width: 62%;

                        .desc-text {
                            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-42) var(--unnamed-font-family-poppins);
                            letter-spacing: var(--unnamed-character-spacing-0);
                            color: var(--unnamed-color-ffffff);
                            text-align: left;
                        }

                        .icons-list {

                        }
                        .btn-wrapper {
                            margin-top: 102px;
                            margin-bottom: 102px;
                        }
                    }
                }

                .right {
                    width: 35%;
                    img {
                        width: 90%;
                    }
                }
            }
        }
    }
}
