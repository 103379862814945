section.offices-list {
  .container {
    padding-left: 276px;
    padding-right: 276px;

    .row-invest-offices {
      margin-top: 98px;

      .col {
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }
}
