section.history {
  min-height: calc(100vh - #{inRem(159)});
  background-color: #026CF5;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: inRem(165) 0 inRem(32) inRem(140);

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: inRem(147);

      .title {
        h2.title-1 {
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-00b7ff);
          text-align: left;
        }
        h3.title-2 {
          font: var(--unnamed-font-style-italic) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-playfair-display);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-ffffff);
          text-align: left;
        }
      }
    }

    .newses-row {
      overflow: hidden;

      .slide-line {
        height: 660px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .grid-news {

          .image {
            background-color: rgba($white, 0.9);
          }

          .intro {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: auto;

            .text {

            }

            .year {
              font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-100)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-poppins);
              letter-spacing: var(--unnamed-character-spacing-0);
              text-align: left;
              color: #016CF5;
              margin-bottom: -16px;
            }
          }
        }
      }
    }
  }
}
